import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CreateBillingPortalSessionService } from '../create-billing-portal-session.service';
import {
  createBillingPortalSessionAction,
  createBillingPortalSessionFailureAction,
  createBillingPortalSessionSuccessAction
} from './create-billing-portal-session.actions';

@Injectable()
export class CreateBillingPortalSessionEffects {
  createBillingPortalSession$ = createEffect(() => this.actions$.pipe(
    ofType(createBillingPortalSessionAction),
    switchMap(action =>
      this.createBillingPortalSessionService.create(action.payload).pipe(
        map(data => createBillingPortalSessionSuccessAction({ data })),
        catchError(error => of(createBillingPortalSessionFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private createBillingPortalSessionService: CreateBillingPortalSessionService,
  ) {
  }
}
