import { Action, createReducer, on } from '@ngrx/store';
import { CreateBillingPortalSessionState } from './create-billing-portal-session.types';
import { createBillingPortalSessionAction, createBillingPortalSessionSuccessAction, createBillingPortalSessionFailureAction } from './create-billing-portal-session.actions';

const initialState = {
  loading: false,
  loaded: false,
  data: null,
  error: null,
};

const createBillingPortalSessionReducer = createReducer<CreateBillingPortalSessionState>(
  initialState,
  on(
    createBillingPortalSessionAction,
    state => ({
      ...state,
      loading: true,
      loaded: false,
      data: null,
      error: null,
    })
  ),
  on(
    createBillingPortalSessionSuccessAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      data: action.data,
    })
  ),
  on(
    createBillingPortalSessionFailureAction,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: false,
      error: action.error,
    })
  ),
);

export const reducer = (state: CreateBillingPortalSessionState, action: Action) => createBillingPortalSessionReducer(state, action);
