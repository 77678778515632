import { Injectable } from '@angular/core';
import { Observable, switchMap, takeWhile } from 'rxjs';
import { doc, docData, Firestore } from '@angular/fire/firestore';
import { select, Store } from '@ngrx/store';
import { selectCurrentUserAccountId } from '../../current-user/store/current-user.selectors';
import { filter } from 'rxjs/operators';
import { inputIsNotNullOrUndefined } from '../../data-access/input-is-not-null-or-undefined';
import { UserService } from '../../auth/services/user.service';

export interface CompanySettings {
  theme?: string,
  logoURL?: string,
  createdAt?: number,
  trackUsersLocation?: boolean,
  tutorialEnabled?: boolean,
  distanceUnit?: 'km' | 'mi',
  lastNetsuiteSyncAt?: number,
  categoriesLocked?: boolean,
  tagsLocked?: boolean,
  // undefined equals company
  paymentType?: 'individual' | 'company',
  // undefined equals events
  dashboardView?: 'events' | 'userReport',
  accountsLocked?: boolean,
  prospectsLocked?: boolean,
  massEmailHideToCc?: boolean,
  firstDayOfWeek?: number,
}

@Injectable({
  providedIn: 'root',
})
export class GetCompanySettingsService {

  constructor(private firestore: Firestore, private store$: Store, private userService: UserService) {
  }

  get(): Observable<CompanySettings | undefined> {
    return this.store$.pipe(
      select(selectCurrentUserAccountId),
      filter(inputIsNotNullOrUndefined),
      switchMap(accountId =>
        docData<CompanySettings>(doc(this.firestore, 'companySettings', accountId)).pipe(
          takeWhile(() => !!this.userService.currentUser),
        )
      ),
    );
  }
}
