import { createAction, props } from '@ngrx/store';
import { ActionTypes } from './action-types';
import { UpdateTagPayload } from '../update-tag.service';

export const updateTagAction = createAction(
  ActionTypes.UPDATE_TAG,
  props<{ payload: UpdateTagPayload }>()
);

export const updateTagSuccessAction = createAction(
  ActionTypes.UPDATE_TAG_SUCCESS,
  props<{ data: any }>()
);

export const updateTagFailureAction = createAction(
  ActionTypes.UPDATE_TAG_FAILURE,
  props<{ error: any }>()
);
