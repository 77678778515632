import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { CreateBillingPortalSessionEffects } from './store/create-billing-portal-session.effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/create-billing-portal-session.reducers';

@NgModule({
  imports: [
    EffectsModule.forFeature([ CreateBillingPortalSessionEffects ]),
    StoreModule.forFeature('createBillingPortalSession', reducer),
  ],
})
export class CreateBillingPortalSessionModule {
}
