import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetCompanySettingsService } from '../get-company-settings.service';
import {
  getCompanySettingsAction,
  getCompanySettingsSuccessAction,
  getCompanySettingsFailureAction,
} from './get-company-settings.actions';

@Injectable()
export class GetCompanySettingsEffects {
  getCompanySettings$ = createEffect(() => this.actions$.pipe(
    ofType(getCompanySettingsAction),
    switchMap(action =>
      this.getCompanySettingsService.get().pipe(
        map(data => getCompanySettingsSuccessAction({ data })),
        catchError(error => of(getCompanySettingsFailureAction({ error }))),
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private getCompanySettingsService: GetCompanySettingsService,
  ) {
  }
}
