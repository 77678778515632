import { defer, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { map } from 'rxjs/operators';

export interface CreateBillingPortalSessionPayload {
  returnUrl: string,
}

export interface CreateBillingPortalSessionResponse {
  url: string,
}

@Injectable({
  providedIn: 'root',
})
export class CreateBillingPortalSessionService {
  constructor(private functions: Functions) {
  }

  create(payload: CreateBillingPortalSessionPayload): Observable<CreateBillingPortalSessionResponse> {
    return defer(() =>
      httpsCallable<CreateBillingPortalSessionPayload, CreateBillingPortalSessionResponse>(this.functions, 'createBillingPortalSession')(payload)
    ).pipe(
      map(response => response.data),
    );
  }
}
