import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { GetCompanySettingsEffects } from './store/get-company-settings.effects';
import { reducer } from './store/get-company-settings.reducers';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ GetCompanySettingsEffects ]),
    StoreModule.forFeature('getCompanySettings', reducer),
  ]
})
export class GetCompanySettingsModule {}
