import { createAction, props } from '@ngrx/store';
import { ActionTypes } from './action-types';

export const getCompanySettingsAction = createAction(
  ActionTypes.GET_COMPANY_SETTINGS
);

export const getCompanySettingsSuccessAction = createAction(
  ActionTypes.GET_COMPANY_SETTINGS_SUCCESS,
  props<{ data: any }>()
);

export const getCompanySettingsFailureAction = createAction(
  ActionTypes.GET_COMPANY_SETTINGS_FAILURE,
  props<{ error: any }>()
);
